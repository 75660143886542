import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap';
import { getReSyncClientList, updateClientByPortalCredentials } from '../../api/clientAPI';
import { BASE_PATH, BULK_CLIENT_RESYNC_LOCAL_STORAGE_KEY, HEADER_CONNECTOR_DIGEST_KEY } from '../../app/constants';
import { verifyItCrendital } from '../../api/localConnectorApi';
import { selectHeaderData } from '../header/headerSlice';
import { useSelector } from 'react-redux';
import ConnectorAdapter from '../../components/kdkConnector/ConnectorAdapter';
import SyncAssessee from '../dashboard/SyncAssessee';
import { LOGIN_PATHS, MISC_PATHS } from '../../App';
import Draggable from 'react-draggable';
import { clearAuthToken, getAuthToken } from '../../utils/AuthToken';

const AssesseeSyncListProgressBar = () => {
    const headerData = useSelector(selectHeaderData);
    const [showConnnectorCall, setShowConnnectorCall] = useState(false);
    const [port, setPort] = useState();
    const [checkItCredentials, setCheckItCredentials] = useState(false);
    const [clientImportProgress, setClientImportProgress] = useState(false)
    const [currentAssessObj, setCurrentAssessObj] = useState('')
    const [syncValidAssessee, setSyncValidAssessee] = useState(null)
    const [syncAssessData, setSyncAssessData] = useState([])
    //const [cursor, setCursor] = useState({});
    const [syncCurrentAssesse, setSyncCurrentAssesse] = useState(0)
    const [syncNextAssessee, setSyncNextAssessee] = useState(true)

    const location = useLocation();

    let shouldRunBulkClientSync = false;
    if (headerData?.mobile_number
        && !LOGIN_PATHS.includes(location.pathname)
        && !MISC_PATHS.includes(location.pathname)
        && ![`${BASE_PATH}dashboard`].includes(location.pathname)
        && ![`${BASE_PATH}return-register/ay-wise`].includes(location.pathname)
    ) {

        shouldRunBulkClientSync = (localStorage.getItem(`${BULK_CLIENT_RESYNC_LOCAL_STORAGE_KEY}${headerData?.mobile_number}`)
            && localStorage.getItem(`${BULK_CLIENT_RESYNC_LOCAL_STORAGE_KEY}${headerData?.mobile_number}`) !== 'false')
            ? false
            : true;
    }


    const [totalCount, setTotalCount] = useState(0);

    const currWorkingPer = totalCount > 0 ? 100 * ((syncCurrentAssesse) / totalCount) : 0;
    const [workDone, setWorkDone] = useState(currWorkingPer > 0 ? currWorkingPer : 0)
    const [currentWorking, setCurrentWorking] = useState(syncCurrentAssesse ?? 1)


    const verfiyCredApi = async (port, syncAssessData, currentRow) => {

        if (!port) {
            return false;
        }

        if (!getAuthToken(false)) {
            clearAuthToken();
            return false
        }

        if (!syncNextAssessee) {

            setTimeout(() => {
                setClientImportProgress(false);
                localStorage.setItem(`${BULK_CLIENT_RESYNC_LOCAL_STORAGE_KEY}${headerData?.mobile_number}`, true);
                window.location.reload()
            }, 2000);

            return false;
        }

        const obj = syncAssessData[currentRow];

        setClientImportProgress(true);
        setCurrentAssessObj(obj);

        const request = {
            "itdportal_username": obj?.pan,
            "itdportal_password": obj?.itdportal_password
        };
        setCurrentWorking((prev) => {
            const curr = ((syncCurrentAssesse));
            if (curr > prev) return curr;
            return prev;
        });
        try {
            if (request.itdportal_password) {
                const result = await verifyItCrendital(port, request);
                if (result.data.status === 1) {
                    const responseHeader = result.headers ?? null;
                    let extraHeader = {};
                    extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;
                    const rowData = {}
                    rowData.data = result?.data?.data;
                    await updateClientByPortalCredentials(rowData, extraHeader, obj.assessee_id);
                    setSyncValidAssessee(obj.assessee_id);
                }
            }
        } catch (err) {
            // Dont display error
            // setError(CONNECTOR_ERRORS_MAPPING[err.message] ?? err.message ?? CONNECTOR_ERRORS_MAPPING['default']);
        }

        finally {

            setWorkDone((prev) => {
                const curr = 100 * ((syncCurrentAssesse) / (totalCount));
                if (curr > prev) return curr;
                return prev;
            });

            getClientDetail(obj?.assessee_id)

        }

    };

    const getClientDetail = async (last_assessee_id) => {
        const urlString = `?last_assessee_id=${last_assessee_id ?? ''}`;

        try {
            const result = await getReSyncClientList(urlString);
            const syncData = result?.data ?? [];
            if (syncData?.data?.length > 0) {
                setSyncCurrentAssesse(syncCurrentAssesse + 1) // current sync assessee counter
                setSyncAssessData(syncData.data);

                if (syncData?.summary) {
                    setTotalCount(syncData?.summary?.total_cnt ?? 0)
                }

                if (!port && !checkItCredentials) setShowConnnectorCall(true);
            } else {
                setSyncNextAssessee(false)
            }
        } catch (err) {
        }
    }


    //if not sync data available in localstorageisRunSync , then this function will run connector to update client data by getting from itr portal, 
    useEffect(() => {
        if (shouldRunBulkClientSync) {
            getClientDetail()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldRunBulkClientSync])


    useEffect(() => {
        if (!(port && checkItCredentials && syncAssessData)) {
            return;
        }

        if (syncAssessData.length <= 0) {
            return;
        }

        verfiyCredApi(port, syncAssessData, 0);

        //eslint-disable-next-line
    }, [port, checkItCredentials, syncAssessData, syncNextAssessee])



    return (
        <>
            {showConnnectorCall ?
                <ConnectorAdapter
                    show={showConnnectorCall}
                    handleClose={() => setShowConnnectorCall(false)}
                    setPort={setPort}
                    handleProceed={() => setCheckItCredentials(true)}
                />
                : null}

            {clientImportProgress &&
                <SyncAssessee
                    assesseeId={syncValidAssessee}
                    handleProceed={() => {
                        setSyncValidAssessee(null);
                    }}
                    port={port}
                />
            }
            {clientImportProgress && <Draggable><div className='processSync progress-sync-popup' style={{ cursor: 'pointer' }}>
                <div className='client-status-show' style={{ color: '#196ec9', fontSize: '12px' }}>Please wait while we are doing our magic to get data from Income Tax Portal!</div>
                <ProgressBar animated now={Math.round(workDone)} label={''} />
                <div className='client-status-show'>Data Sync is in progress for: <span>{String(currentAssessObj?.full_name ?? '') ? currentAssessObj?.full_name + '-' + currentAssessObj?.pan : currentAssessObj?.pan}</span></div>
                <div className='client-status-show'>Time Remaining:<span>About {Math.max(Math.ceil((totalCount - currentWorking) * .1), 1)} {Math.max(Math.ceil((totalCount - currentWorking) * .1), 1) > 1 ? 'Minutes' : 'Minute'}  </span>   <div className='show-progress-count'>Count:<span>{currentWorking ?? 1}/{totalCount}</span></div></div>

            </div></Draggable>}

        </>
    )
}

export default AssesseeSyncListProgressBar  