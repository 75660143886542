/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye, faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import NumericInput from '../../components/NumericInput';
import ShowAlert from '../../components/ShowAlert';
import loginleftside from '../../images/loginicon/login_left_side.png';
import Indiaflagexp from '../../images/loginicon/India-flag-exp.png';
import { loginUser } from '../../api/authenticationAPI';
import { BASE_PATH } from '../../app/constants';
import { getRedirectUrl, removeRedirectUrl } from '../../utils/UtilityFunctions';
import { selectAppData, setAppData } from '../../app/appDataSlice';
import { salesiq_chatoff,initializeSalesIq } from './visitor_info.js';
import exclamationIcon from '../../images/exclamation.png';
import { encryptPasswordString } from '../../utils/UtilityFunctions';
import './LoginScreen.scss';
import { updateAuthToken } from '../../utils/AuthToken';

const LoginScreen = ({ history }) => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  initializeSalesIq();
  const [mobileNo, setMobileNo] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  const appData = useSelector(selectAppData);
  const dispatch = useDispatch();

  const handleBlur = (val, field) => {
    if(field === 'mobileNo') {
      if(val.trim().length === 0){ 
        setFormErrors({...formErrors, mobileNo: 'This field is required'})
      }else if(Number(val) < 5999999999 || Number(val) > 9999999999) {
        setFormErrors({...formErrors, mobileNo: 'Invalid Mobile Number'})
      }else{
        setFormErrors({...formErrors, mobileNo: ''})
      }
    }
    if(field === 'password') {
      if(val.trim().length === 0){ 
        setFormErrors({...formErrors, password: 'This field is required'})      
      }else{
        setFormErrors({...formErrors, password: ''})
      }
    }    
  }

  const login = async (e) => {
    e.preventDefault();
    const formErr = {};
    if (mobileNo.trim().length === 0) {
      formErr.mobileNo = 'This field is required';
    } else if (mobileNo < 5999999999 || mobileNo > 9999999999) {
      formErr.mobileNo = 'Invalid Mobile Number';
    }
    if (password.trim().length === 0) {
      formErr.password = 'This field is required';
    }
    setFormErrors(formErr);
    if (Object.keys(formErr).length > 0) return;

    setLoading(true);

    const data = {
      country_code: '+91',
      mobile_no: parseInt(mobileNo),
      password: encryptPasswordString(password),
    };
    try {
      const result = await loginUser(data);
      updateAuthToken(result.data.token);
      salesiq_chatoff();
      let redirect_url = getRedirectUrl();
      if (redirect_url) {
        removeRedirectUrl();
        history.push(redirect_url);
      }
      else {
        history.push(`${BASE_PATH}client/recent`);
      }
    } catch (err) {
      setError(err.message);
      console.error('Error in send otp: ', err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (appData.authError) {
      setError(appData.authError);
      setTimeout(() => {
        dispatch(setAppData({ authError: "" }));
      }, 3000);
    }
  }, [appData.authError]);

  return (
    <div className='main-loginscreen'>
     <ShowAlert
        error={error}
        onClose={() => { setError(null); }}
      />
      <div className="container clear-padd mt-2">

        <div className="col-xs-2 col-sm-2 col-sm-2 login-empty-container" />
        <div className="col-xs-8 col-lg-8 col-md-12 col-sm-12 login-container mt-28 vertical-center">

          <div className="row">
            <div className=" col-md-8 mt-2">
              <p className="">
                <span className="fileetdssm" style={{ color: '#f27b72' }}> IT Compliance made hassle-free</span>

              </p>
            </div>
            <div className=" col-md-4 mt-2 pr-0">
              <span className="btn btn-default float-right backhbtnls">
                <a href="https://www.expressitr.com/">  Back to Home</a>
              </span>
            </div>

          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-12  col-xs-6 col-sm-6 col-md-6 login-img-caontainer">
              <img className="" src={loginleftside} alt="mobile" ng-click="close()" />
            </div>

            <div className=" login-form-container col-xs-12 col-sm-12 col-xs-6 col-sm-6 col-md-6 bg-whitecolor formBorder">

              <h2 className="mt-45 col-md-offset-1 loginHeader ng-binding ml-0 text-center" style={{ color: '#31719b' }}>Welcome to Express IT</h2>
              <h5 className="mt-42 col-md-offset-1 font-16 ng-binding subtitle ml-0 text-center">Login with your Mobile Number</h5>
              <form className="form-horizontal logforms" onSubmit={(e) => login(e)}>
                <div className="form-group form-group2">
                  <div className="col-xs-10 col-sm-10 col-md-10 login-form-input-box">

                    <div className="input-group">
                    <div className={`input-group-prepend inpgpbx ${formErrors?.mobileNo && formErrors.mobileNo !== '' ? 'input-error1' : ''}`}>
                        <img className="flag_icon " src={Indiaflagexp} alt="mobile" />
                      </div>
                      <NumericInput
                        type="tel"
                        className={`form-control lg-input inputplace inputplace1 input-auto-size ${formErrors?.mobileNo && formErrors.mobileNo !== '' ? 'input-error1' : ''}`}
                        name="Mobile"
                        placeholder="Enter Mobile Number"
                        required=""
                        value={mobileNo}
                        maxLength={10}
                        onChange={(e) => {setFormErrors('mobileNo', ''); setMobileNo(e.target.value)}}
                        onBlur={(e) => handleBlur(e.target.value, 'mobileNo')}
                      />

                    </div>
                    {formErrors.mobileNo ? <span className="signup_error_msg"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{formErrors.mobileNo}</span> : null}
                  </div>
                </div>
                <div className="form-group form-group2">
                  <div className="col-xs-10 col-sm-10 col-md-10 login-form-input-box">
                    {showPassword ? (
                      <div className="input-group">
                        <input
                          type="input"
                          className={`form-control lg-input inputplace inputplace1 input-auto-size ${formErrors?.password && formErrors.password !== '' ? 'input-error1' : ''}`}
                          name="password"
                          placeholder="Enter Password"
                          required=""
                          value={password}
                          onChange={(e) => {setFormErrors('password', ''); setPassword(e.target.value)}}
                          onBlur={(e) => handleBlur(e.target.value, 'password')}
                          maxLength={100}
                        />
                        <div className={`input-group-prepend eyeipbox ${formErrors?.password && formErrors.password !== '' ? 'input-error1' : ''}`} onClick={() => setShowPassword(false)} style={{ cursor: 'pointer' }}>
                          <FontAwesomeIcon icon={faEyeSlash} />
                        </div>
                      </div>
                    ) : (
                      <div className="input-group">
                        <input
                          type="password"
                          className={`form-control lg-input inputplace inputplace1 input-auto-size ${formErrors?.password && formErrors.password !== '' ? 'input-error1' : ''}`}
                          name="password"
                          placeholder="Enter Password"
                          required=""
                          value={password}
                          onChange={(e) => {setFormErrors('password', ''); setPassword(e.target.value)}}
                          autoComplete="new-password"
                          onBlur={(e) => handleBlur(e.target.value, 'password')}
                          maxLength={100}
                        />
                        <div className={`input-group-prepend eyeipbox ${formErrors?.password && formErrors.password !== '' ? 'input-error1' : ''}`} onClick={() => setShowPassword(true)} style={{ cursor: 'pointer' }}>
                          <FontAwesomeIcon icon={faEye} />
                        </div>
                      </div>
                    )}

                    {formErrors.password ? <span className="signup_error_msg"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{formErrors.password}</span> : null}
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-xs-10 col-sm-10 col-md-10 col-md-offset-3 login-form-input-box">

                    <Button
                      type="submit"
                      bsPrefix="form-control btn red-btn semi-bold btnFont"
                      name="Login"
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          {' '}
                          Loading...
                        </>
                      ) : 'LOGIN'}
                    </Button>
                  </div>
                </div>

                <div className="col-sm-12 col-xs-8 col-sm-12 col-md-12 col-md-offset-2 text-center padding-25 mtn-37">
                  <p className="ng-binding alsintextsiz">
                    <span className="">
                      <Link className="regtext" to={`${BASE_PATH}forgetpass?mobile_no=${mobileNo}`}>Forgot Password?</Link>
                    </span>

                  </p>
                </div>
                <div className="col-sm-12 col-xs-8 col-sm-12 col-md-12 col-md-offset-2 text-center padding-25 mtn-37">
                  <p className="ng-binding alsintextsiz">
                    <span className="pr-2">Don't have an account?</span>
                    {params.utm_source ? (
                      <Link className="regtext" to={`${BASE_PATH}signup?utm_source=${params.utm_source}`}>Sign Up</Link>
                    ) : (
                      <Link className="regtext" to={`${BASE_PATH}signup`}>Sign Up</Link>
                    )}

                  </p>
                </div>

              </form>
            </div>

          </div>
        </div>
        <div className="col-sm-2 login-empty-container" />

      </div>
    </div>

  );
};

export default LoginScreen;