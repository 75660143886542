/* eslint-disable import/prefer-default-export */
/* eslint-disable eqeqeq */
import moment from "moment";
import {
  BASE_PATH,
  CONNECTOR_LOCAL_PORTS,
  CONNECTOR_RELEASED_VERSIONS,
  PASSWORD_ENCRYPTION_KEY,
  PASSWORD_ENCRYPTION_IV,
  initialHeaderData
} from "../app/constants";
import { getConnectionStatus } from "../api/localConnectorApi";
import { LOGIN_PATHS } from "../App";
import CryptoJS from "crypto-js";
import { getAuthToken, updateAuthToken } from "./AuthToken";
import store from "../app/store";
import { setHeader } from "../features/header/headerSlice";
import history from "../app/history";

/**
 * Function to get assessment years
 * @return: [
    {2022: '2022-23'},
    {2023: '2023-24'},
   ]
 */
export function getAssessmentYears() {
  const AYs = [];
  let startYear = getMaxAssessmentYearForWorking();
  while (startYear >= getMinAssessmentYearForWorking()) {
    AYs.push({
      label: String(`${startYear}-${(startYear + 1) % 100}`),
      value: String(startYear),
    });
    startYear -= 1;
  }

  return AYs;
}

export function getMinAssessmentYearForWorking() {
  return 2022;
}

export function getMaxAssessmentYearForWorking() {
  const today = new Date();
  const todayMonth = Number(today.getMonth());
  const todayYear = Number(today.getFullYear());

  // From 1st october onwards, next AY = (Today Year + 1) will be open
  if (todayMonth >= 10) {
    return todayYear + 1;
  }

  // For January to March, next AY = Today Year to be sent.
  // For April to September, current AY = Today Year to be sent.
  return todayYear;
}

export function minYearToBeSelct() {
  return 1947;
}

export function checkForAutoLogin() {
  if (window.location.hash) {
    let token = window.location.hash.substring(1);
    updateAuthToken(token);
    let url = window.location.pathname + window.location.search;
    window.history.pushState({}, "", url);
  }
}


export function isTokenValid() {
  checkForAutoLogin();

  //Get auth token - don't fail if invalid
  if (!getAuthToken(false)) {
    saveRedirectUrl();
    store.dispatch(setHeader(initialHeaderData));
    return false;
  }

  return true;
}

export function redirectToLoginPage() {
  history.push(`${BASE_PATH}login`)
}

export function saveRedirectUrl() {
  if (!LOGIN_PATHS.includes(window.location.pathname)) {
    let data = {
      path: window.location.pathname + window.location.search,
      time: moment().add(1, "hours").unix(),
    };
    localStorage.setItem("redirect-url", JSON.stringify(data));
  }
}

export function getRedirectUrl() {
  let redirect_url = localStorage.getItem("redirect-url");
  if (!redirect_url) return false;

  redirect_url = JSON.parse(redirect_url);

  if (redirect_url.time > moment.unix()) return false;
  return redirect_url.path;
}

export function removeRedirectUrl() {
  localStorage.removeItem("redirect-url");
}

export function formatNumber(num) {
  if (isNaN(num)) return num;

  if (!num) num = 0;
  let n = (Math.round(num * 10000) / 10000).toFixed(4);
  let ls = Number(n).toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  });

  // eslint-disable-next-line eqeqeq
  // if (n == parseInt(n)) ls = ls + ".00";

  let check_decimal = ls.split(".");
  if (check_decimal[1] && check_decimal[1].length === 1) {
    ls = ls + "0";
  }
  return ls;
}

export function formatAccountsNumber(num) {
  if (isNaN(num)) return num;

  if (!num) num = 0;
  let n = (Math.round(num * 100) / 100).toFixed(2);
  let ls = Number(n).toLocaleString("en-IN");
  // eslint-disable-next-line eqeqeq
  if (n == parseInt(n)) ls = ls + ".00";

  let check_decimal = ls.split(".");
  if (check_decimal[1] && check_decimal[1].length === 1) {
    ls = ls + "0";
  }
  if (n < 0) {
    ls = ls.substring(1);
    return <span className="redtextclr">({ls})</span>;
  }
  return ls;
}

export function formatAccountsValue(num) {
  if (isNaN(num)) return num;

  if (!num) num = 0;
  let n = (Math.round(num * 100) / 100).toFixed(2);
  let ls = Number(n).toLocaleString("en-IN");
  // eslint-disable-next-line eqeqeq

  let check_decimal = ls.split(".");
  if (check_decimal[1] && check_decimal[1].length === 1) {
    ls = ls + "0";
  }
  if (n < 0) {
    ls = ls.substring(1);
    return <span className="">({ls})</span>;
  }
  return ls;
}

export function validateGSTNumber(gstNumber) {
  if (
    !/[0-9]{2}[A-Z]{4}[A-Z0-9]{1}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[A-Z0-9]{1}[A-Z0-9]{1}/.test(
      gstNumber
    )
  ) {
    return false;
  }

  const CHECKSUM_WEIGHT_CHARACTERS = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  const CHECKSUM_FACTOR_EVEN_PLACE = 1;
  const CHECKSUM_FACTOR_ODD_PLACE = 2;

  let gst_number_last_letter = gstNumber[gstNumber.length - 1];
  let sum = 0;

  let factor = CHECKSUM_FACTOR_EVEN_PLACE;

  let checksum_mod = CHECKSUM_WEIGHT_CHARACTERS.length;
  for (let i = 0; i < gstNumber.length - 1; i++) {
    let current_letter_weight = CHECKSUM_WEIGHT_CHARACTERS.indexOf(
      gstNumber[i]
    );

    if (current_letter_weight !== -1) {
      let current_checksum_digit = current_letter_weight * factor;
      current_checksum_digit =
        parseInt(current_checksum_digit / checksum_mod) +
        (current_checksum_digit % checksum_mod);
      sum += current_checksum_digit;
    }
    factor =
      factor === CHECKSUM_FACTOR_EVEN_PLACE
        ? CHECKSUM_FACTOR_ODD_PLACE
        : CHECKSUM_FACTOR_EVEN_PLACE;
  }

  let calculated_checksum_weight =
    (checksum_mod - (sum % checksum_mod)) % checksum_mod;
  let calculated_checksum_letter =
    CHECKSUM_WEIGHT_CHARACTERS[calculated_checksum_weight] ?? "";
  if (calculated_checksum_letter !== gst_number_last_letter) {
    return false;
  }

  return true;
}

export function isValidEmail(emailId) {
  return String(emailId)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export const FormatNumberInt = (val, maxDigit = 14, negative = false) => {
  let value = String(val);
  value = value.replace(/[^\d.-]/g, "");
  if (isNaN(value)) {
    value = value.slice(0, -1);
  }
  value = value.replace(/[\D\s_-]+/g, "");
  value = value.slice(0, maxDigit);
  value = value ? parseInt(value, 10) : "";
  if (negative === true && String(val)[0] === "-") {
    return "-" + value.toLocaleString("en-IN");
  }
  return value === 0 ? "0" : value.toLocaleString("en-IN");
};
export const FormatNumberIntValue = (val, maxDigit = 14, negative = false) => {
  let value = String(val).replace(/[^\d.-]/g, "");
  value = value.substring(0, maxDigit);
  if (isNaN(value)) return "";
  return value;
};

export const FormatNumberDecimal = (
  getValue,
  dotBeforeDigit = 14,
  dotAfterDigit = 2,
  negative = true
) => {
  let val = String(getValue);
  let value = val;
  value = value.replace(/[^\d.-]/g, "");
  if (isNaN(value) && value !== ".") {
    value = value.slice(0, -1);
  }
  let tmp = String(value).split(".");
  if (tmp.length === 1) {
    value = value.replace(/[\D\s_-]+/g, "");
    value = value.slice(0, dotBeforeDigit);

    value = value ? parseFloat(value, 10) : "";
    if (negative === true && String(val)[0] === "-") {
      return "-" + value.toLocaleString("en-IN");
    }
    return value === 0 ? "0" : value.toLocaleString("en-IN");
  } else if (tmp.length === 2) {
    let value1 = tmp[0].replace(/[\D\s_-]+/g, "");
    let value2 = tmp[1].replace(/[\D\s_-]+/g, "");

    if (dotBeforeDigit && dotBeforeDigit > 0) {
      value1 = value1.slice(0, dotBeforeDigit);
    }
    //  if (dotAfterDigit && dotAfterDigit > 0) {
    value2 = value2.slice(0, dotAfterDigit);
    //  }

    value1 = value1 ? parseFloat(value1, 10) : 0;
    if (value2[0] === "0") {
      if (value2.length > 1) value2 = "0" + parseFloat(value2, 10);
      else value2 = parseFloat(value2, 10);
    } else {
      value2 = value2 ? parseFloat(value2, 10) : "";
    }

    if (negative === true && String(val[0]) === "-") {
      return "-" + value1.toLocaleString("en-IN") + "." + value2;
    }
    return value1.toLocaleString("en-IN") + "." + value2;
  }
};
export const FormatNumberDecValue = (
  val,
  maxDigit = 14,
  decimal = 2,
  negative = false,
  decimalAllow = true,
  e = null
) => {
  let countComma = String(val).split(".").length - 1;
  if (countComma >= 2) {
    val = val.slice(0, -1);
  }
  if (val === null || val.length === 0) {
    return "";
  }
  if (val === "-" && negative === false) {
    return "";
  }
  if (/^[^0-9-.]*$/.test(val)) {
    return "";
  }

  val = String(val).replace(/-+/g, "-");
  val = val[0] + val.substr(1).replace(/[^0-9. ]/g, "");
  let value = String(val);
  if (negative === true && String(val[0]) === "-") {
    value = value.slice(1);
    if (value.length === 0) {
      return "-";
    }
  }

  let tmp = String(value).split(".");
  let newValue = 0;
  if (tmp.length === 1 || !decimalAllow) {
    if (!negative) {
      value = value.replace(/[^\d]/g, "");
    }
    newValue = Number(FormatNumberIntValue(value, maxDigit));
  } else if (tmp.length === 2) {
    let value1 = FormatNumberIntValue(tmp[0], maxDigit);
    let value2 = tmp[1];
    newValue = value1 + "." + String(value2).slice(0, decimal);
    if (Math.pow(10, maxDigit) <= Number(value1) + 1) {
      newValue = value1;
    }
  }

  if (e) {
    const oldValue = e.target.defaultValue;
    let start = e.target.selectionStart;
    const showValue = FormatNumberDecimal(newValue, ...Array(2), true);
    start = start + String(showValue).length - String(e.target.value).length;

    if (
      oldValue === showValue &&
      e?.nativeEvent?.inputType === "deleteContentBackward"
    ) {
      start = start - 1;
    }
    if (
      negative === true &&
      String(val[0]) === "-" &&
      e?.nativeEvent?.inputType !== "deleteContentBackward"
    ) {
      start = start + 1;
    }
    if (start < 0) start = 0;
    setTimeout(() => {
      e.target.setSelectionRange(start, start);
    }, 0);
  }

  if (negative === true && String(val[0]) === "-") {
    if (!Number.isNaN(newValue)) return "-" + newValue;
  }
  if (String(newValue).substr(String(newValue).length - 1) !== ".")
    return Number(newValue);
  return newValue;
};

async function getRunningConnectorDetails() {
  for (let i = 0; i < CONNECTOR_LOCAL_PORTS.length; i++) {
    try {
      const result = await getConnectionStatus(CONNECTOR_LOCAL_PORTS[i]);
      if (parseInt(result.status ?? 0) === 1) {
        return {
          port: CONNECTOR_LOCAL_PORTS[i],
          version: parseInt(JSON.parse(atob(result.data)).version ?? 0),
        };
      }
    } catch (error) {}
  }

  return { port: 0, version: 0 };
}

export async function getRunningConnectorUsability() {
  // Get Running Connector Details
  const runningConnectorDetails = await getRunningConnectorDetails();

  // No connector Running. It is mandatory to download Connector installer
  if (runningConnectorDetails.port === 0) {
    return {
      port: 0,
      install: 1,
      update: 0,
    };
  }

  // Get Latest And Stable Released version
  const cv = parseInt(CONNECTOR_RELEASED_VERSIONS.cv ?? 0);
  const sv = parseInt(CONNECTOR_RELEASED_VERSIONS.sv ?? 0);

  // If Running version is less than the Stable Version, then we need to Download Installer
  if (runningConnectorDetails.version < sv) {
    return {
      port: runningConnectorDetails.port,
      install: 1,
      update: 0,
    };
  }

  // Running version >= Stable version
  // However, if it is less than the Latest version, then we can proceed. However, user can update also (if needed)
  if (runningConnectorDetails.version < cv) {
    return {
      port: runningConnectorDetails.port,
      install: 0,
      update: 1,
    };
  }

  // Else, all good.
  return {
    port: runningConnectorDetails.port,
    install: 0,
    update: 0,
  };
}

export const deepClone = (data) => {
  return JSON.parse(JSON.stringify(data));
};

export function genRandNum(length) {
  const characters = "0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function convertNum(value) {
  if (isNaN(value)) return 0;
  return Number(value);
}
//pass all key as a object key in data which are using initialFormula, one () will allow only either (multiple + and -) or (multiple * and /)
// run it like this calculateWithKeys('((a+b+c)+d)+(e*f) + test_Key + ((50 + 30 + (500 * 10/13) + 11) + 30)', { test_Key:11,a: 5, b: 10, c: 10, d: 66, e: 5435, f: 66 })
export const calculateWithKeys = (initialFormula, data) => {
  let returnValue = null;
  if (!isNaN(initialFormula)) {
    return Number(initialFormula);
  }
  function runFormulaFunc(runFormulaValue, data) {
    let leftBrk = [];
    let rightBrk = [];
    for (let i = 0; i < runFormulaValue.length; i++) {
      if (runFormulaValue[i] === "(") leftBrk.push(i);
    }
    for (let i = 0; i < runFormulaValue.length; i++) {
      if (runFormulaValue[i] === ")") rightBrk.push(i);
    }
    if (leftBrk.length !== rightBrk.length) {
      return null;
    } else if (leftBrk.length === rightBrk.length) {
      let leftFinal;
      let rightFinal = rightBrk[0];
      for (const element of leftBrk) {
        if (rightBrk[0] > element) {
          leftFinal = element;
        }
      }

      function calValue(runnableFormula) {
        let positionOne = null;
        let counter = 0;
        let i = 0;
        let key1 = null;
        let operator = null;
        let key2 = null;
        let negativeAtFirst = false;
        for (const element of runnableFormula) {
          if (["-"].includes(element) && i == 0) {
            negativeAtFirst = true;
          }
          if (["+", "-", "*", "/"].includes(element) && i !== 0) {
            counter = counter + 1;
            if (positionOne) {
              key2 = runnableFormula.substring(positionOne + 1, i);
            }
            if (!positionOne) {
              positionOne = i;
              operator = element;
            }
            if (!key1) {
              key1 = runnableFormula.substring(0, positionOne);
            }
          }
          if (counter === 2) {
            break;
          }
          i = i + 1;
        }
        if (counter === 0) {
          if (negativeAtFirst) {
            return !isNaN(runnableFormula)
              ? runnableFormula
              : -data[runnableFormula.substring(1, runnableFormula.length)] ??
                  0;
          }
          return !isNaN(runnableFormula)
            ? runnableFormula
            : data[runnableFormula] ?? 0;
        }
        if (!key2) {
          key2 = runnableFormula.substring(positionOne + 1, i);
        }
        let value1 = !isNaN(key1) ? key1 : data[key1] ?? 0;
        let value2 = !isNaN(key2) ? key2 : data[key2] ?? 0;
        if (negativeAtFirst) {
          value1 = !isNaN(key1)
            ? key1
            : -data[key1.substring(1, key1.length)] ?? 0;
        }
        let newValue = null;
        if (operator === "+") {
          newValue = Number(value1 ?? 0) + Number(value2 ?? 0);
        } else if (operator === "-") {
          newValue = Number(value1 ?? 0) - Number(value2 ?? 0);
        } else if (operator === "*") {
          newValue = Number(value1 ?? 0) * Number(value2 ?? 0);
        } else if (operator === "/") {
          newValue = Number(value1 ?? 0) / Number(value2 ?? 1);
        }
        if (counter < 2) {
          return newValue;
        } else {
          let newFormula =
            newValue + runnableFormula.substring(i, runnableFormula.length);
          return calValue(String(newFormula));
        }
      }
      let runAtOnetime = runFormulaValue.substring(leftFinal + 1, rightFinal);
      let calculateValue = calValue(runAtOnetime);
      let newFormula =
        runFormulaValue.substring(0, leftFinal ?? 0) +
        String(calculateValue) +
        runFormulaValue.substring(
          rightFinal ? rightFinal + 1 : runFormulaValue.length,
          runFormulaValue.length
        );
      if (!isNaN(newFormula)) {
        returnValue = Number(newFormula);
        return null;
      } else {
        return runFormulaFunc(newFormula, data);
      }
    } else {
      return null;
    }
  }
  runFormulaFunc(initialFormula.replaceAll(" ", ""), data);
  return returnValue;
};

//to defined nested object key use a.b.c.d ;
//to defined nested array key use a.b.c.2.d or a.b.c.2; 2 is the index number
export const setNestedProperty = (nestObj, nestedPath, value) => {
  //intiliaze nestobj should be {} not []
  const [head, ...rest] = nestedPath.split(".");
  if (/\d/.test(head)) {
    //for nextobj = [{a:1}] or [a,b] //supported path - 2.a,2.a.b.c for obj, 2 for values
    nestObj[head] = rest.length
      ? setNestedProperty(nestObj[head] ?? {}, rest.join("."), value)
      : value;
    return [...nestObj];
  }
  if (rest.length > 0 && /\d/.test(rest[0])) {
    const [index, ...newRest] = rest;
    nestObj[head][index] = newRest.length
      ? setNestedProperty(nestObj[head][index] ?? {}, newRest.join("."), value)
      : value;
    return {
      ...nestObj,
    };
  }
  return {
    ...nestObj,
    [head]: rest.length
      ? setNestedProperty(nestObj[head] ?? {}, rest.join("."), value)
      : value,
  };
};

export const downloadPdfFile = (pdfData, filename) => {
  const linkSource = `data: application/pdf;base64,${pdfData}`;
  const downloadLink = document.createElement("a");
  const fileName = filename;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export function getRevampedStatusAsPerExistingSubStatusId(pan, subStatusId) {
  let fourthCharPAN = "";
  if (pan.length === 10) {
    fourthCharPAN = pan.substring(3, 4);
  }
  switch (subStatusId) {
    case 3:
      return 11;
    case 4:
      if (fourthCharPAN === "T") {
        return 904;
      }
      return 13;
    case 5:
      return 529;
    case 6:
      return 629;
    case 8:
      return 28;
    case 9:
      return 929;
    case 17:
      if (fourthCharPAN === "T") {
        return 917;
      }
      break;
    case 24:
      if (fourthCharPAN === "A") {
        return 524;
      } else if (fourthCharPAN === "B") {
        return 624;
      }
      break;
    case 25:
      if (fourthCharPAN === "A") {
        return 525;
      } else if (fourthCharPAN === "B") {
        return 625;
      }
      break;
    case 27:
      if (fourthCharPAN === "A") {
        return 527;
      } else if (fourthCharPAN === "B") {
        return 627;
      }
      break;
    case 29:
      if (fourthCharPAN === "A") {
        return 529;
      } else if (fourthCharPAN === "B") {
        return 629;
      } else if (fourthCharPAN === "T") {
        return 929;
      }
      break;
    default:
      console.warn("Unhandled subStatusId:", subStatusId);
      // Provide a default value or handle the warning as needed
      return subStatusId;
  }
}

export function scrollbarTopPosition(id, validateValue) {
  const position = document.getElementById(id)?.getBoundingClientRect();
  if (position?.top < validateValue) window.scrollTo(0, 0);
}

export function encryptPasswordString(password) {
  let key = CryptoJS.enc.Utf8.parse(PASSWORD_ENCRYPTION_KEY);
  let iv = CryptoJS.enc.Utf8.parse(PASSWORD_ENCRYPTION_IV);
  var encryptedPassword = CryptoJS.AES.encrypt(password, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();

  return encryptedPassword;
}

export function listAssmentYearsFrom(fromYear) {
  const AYs = [];
  let startYear = getMaxAssessmentYearForWorking();
  while (startYear >= fromYear) {
    AYs.push({
      text: String(`${startYear}-${(startYear + 1) % 100}`),
      value: parseInt(startYear),
    });
    startYear -= 1;
  }

  return AYs;
}
