import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap';
import { getClientAlllist } from '../../api/clientAPI';
import { BASE_PATH, BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY } from '../../app/constants';
import { selectHeaderData } from '../header/headerSlice';
import { useSelector } from 'react-redux';
import ConnectorAdapter from '../../components/kdkConnector/ConnectorAdapter';
import SyncAssessee from '../dashboard/SyncAssessee';
import { LOGIN_PATHS, MISC_PATHS } from '../../App';
import Draggable from 'react-draggable';
import { clearAuthToken, getAuthToken } from '../../utils/AuthToken';

const AssesseeSyncAllClientListProgressBar = (props) => {
    const { setShowSyncButton = null, runSync = null, display } = props;
    const headerData = useSelector(selectHeaderData);
    const [showConnnectorCall, setShowConnnectorCall] = useState(false);
    const [port, setPort] = useState();
    const [checkItCredentials, setCheckItCredentials] = useState(false);
    const [clientImportProgress, setClientImportProgress] = useState(false)
    const [currentAssessObj, setCurrentAssessObj] = useState('')
    const [syncValidAssessee, setSyncValidAssessee] = useState(null)
    const [syncAssessData, setSyncAssessData] = useState([])
    const timer = useRef(null);
    const location = useLocation();

    let shouldRunBulkClientSync = false;
    if (headerData?.mobile_number
        && !LOGIN_PATHS.includes(location.pathname)
        && !MISC_PATHS.includes(location.pathname)
        && ![`${BASE_PATH}dashboard`].includes(location.pathname)
    ) {

        shouldRunBulkClientSync = (sessionStorage.getItem(`${BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY}${headerData?.mobile_number}`)
            && sessionStorage.getItem(`${BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY}${headerData?.mobile_number}`) !== 'false')
            ? false
            : true;
    }

    const bulkSyncLocalDataObj = sessionStorage.getItem(`${BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY}${headerData?.mobile_number}-data`) ? JSON.parse(sessionStorage.getItem(`${BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY}${headerData?.mobile_number}-data`)) : null;
    const bulkSyncLocalData = bulkSyncLocalDataObj?.syncData ?? [];
    const [totalCount, setTotalCount] = useState(bulkSyncLocalDataObj ? bulkSyncLocalDataObj?.totalSyncCount : 0);
    const syncCompleted = bulkSyncLocalDataObj ? bulkSyncLocalDataObj?.syncCompleteCount : 0;

    const currWorkingPer = totalCount > 0 ? 100 * ((syncCompleted) / totalCount) : 0;
    const [workDone, setWorkDone] = useState(currWorkingPer > 0 ? currWorkingPer : 0)
    const [currentWorking, setCurrentWorking] = useState(syncCompleted > 0 ? syncCompleted : 1)

    const verfiyCredApi = async (port, syncAssessData, totalAssessee, currentRow) => {
        if (!port) {
            return false;
        }

        if (!getAuthToken(false)) {
            clearAuthToken();
            return false
        }

        const obj = syncAssessData[currentRow];

        const tempBulkSyncDataObj = sessionStorage.getItem(`${BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY}${headerData?.mobile_number}-data`) ? JSON.parse(sessionStorage.getItem(`${BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY}${headerData?.mobile_number}-data`)) : null;
        const tempBulkSyncData = tempBulkSyncDataObj?.syncData ?? [];

        const index = tempBulkSyncData.findIndex(x => x.pan === obj?.pan);
        if (index > -1) { tempBulkSyncData.splice(index, 1) }

        setClientImportProgress(true);
        setCurrentAssessObj(obj);
        setSyncValidAssessee(obj.assessee_id);
            timer.current = setTimeout(() => {
                sessionStorage.setItem(`${BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY}${headerData?.mobile_number}-data`, JSON.stringify(
                    { syncData: tempBulkSyncData, totalSyncCount: totalCount, syncCompleteCount: ((syncCompleted) + currentRow + 1) }
                ));
                setWorkDone((prev) => { const curr = 100 * ((currentRow + 1 + syncCompleted) / (totalCount > 0 ? totalCount : totalAssessee)); if (curr > prev) return curr; return prev; });
                setCurrentWorking((prev) => { const curr = ((currentRow + 1 + syncCompleted)); if (curr > prev) return curr; return prev; });

                if ((currentRow + 1) === totalAssessee) {
                    setClientImportProgress(false);
                    if (setShowSyncButton) setShowSyncButton(false)
                    sessionStorage.removeItem(`${BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY}${headerData?.mobile_number}-data`);
                    sessionStorage.removeItem(`${BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY}${headerData?.mobile_number}`);
                } else {
                    verfiyCredApi(port, syncAssessData, totalAssessee, currentRow + 1);
                }
            }, 5000);
        
    };


    const getClientListWithPassword = async () => {
        if (!runSync) {
            if (setShowSyncButton) setShowSyncButton(true)
            return;
        }
        const userData = { apiCalled: false, data: [] }
        try {
            const result = await getClientAlllist({ filters: null, limit: 1000, page: null });


            if (result.data.data && result.data.data.length > 0 && runSync) {
                setTotalCount(result.data.data.length)
                userData.apiCalled = true;
                result.data.data.forEach((item) => {
                    userData.data.push({ pan: item?.pan, itdportal_password: "", assessee_id: item?.assessee_id, full_name: item?.full_name })
                })
                sessionStorage.setItem(`${BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY}${headerData?.mobile_number}-data`, JSON.stringify({ syncData: userData?.data, totalSyncCount: result.data.data.length, syncCompleteCount: 0 }));
                return userData
            }
        } catch (err) {
            // setError(err);
            return []
        }

    }




    //if not sync data available in localstorageisRunSync , then this function will run connector to update client data by getting from itr portal, 
    useEffect(() => {
        if (shouldRunBulkClientSync) {
            (async () => {
                try {
                    const result = bulkSyncLocalData && bulkSyncLocalData?.length > 0 ? { data: bulkSyncLocalData } : await getClientListWithPassword();
                    const syncData = result?.data ?? []
                    if (syncData.length > 0) {
                        setSyncAssessData(syncData);
                        setShowConnnectorCall(true);
                    } else if (result?.apiCalled) {
                        sessionStorage.setItem(`${BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY}${headerData?.mobile_number}`, true);
                    }
                } catch (err) {
                }
            })();

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldRunBulkClientSync, runSync])


    useEffect(() => {
        if (!(port && checkItCredentials && syncAssessData)) {
            return;
        }

        if (syncAssessData.length <= 0) {
            return;
        }

        verfiyCredApi(port, syncAssessData, syncAssessData.length, 0);

        //eslint-disable-next-line
    }, [port, checkItCredentials, syncAssessData])

    useEffect(() => {
        return () => clearTimeout(timer.current);
    }, [])


    return (
        <>
            {showConnnectorCall ?
                <ConnectorAdapter
                    show={showConnnectorCall}
                    handleClose={() => setShowConnnectorCall(false)}
                    setPort={setPort}
                    handleProceed={() => setCheckItCredentials(true)}
                />
                : null}

            {clientImportProgress &&
                <SyncAssessee
                    assesseeId={syncValidAssessee}
                    handleProceed={() => {
                        setSyncValidAssessee(null);
                    }}
                    port={port}
                    hardResync={true}
                />
            }
            {clientImportProgress && display && <Draggable><div className='processSync progress-sync-popup' style={{ cursor: 'pointer' }}>
                <div className='client-status-show' style={{ color: '#196ec9', fontSize: '12px' }}>We are updating your Return Register. It should be completed before you take a sip of coffee.</div>
                <ProgressBar animated now={Math.round(workDone)} label={''} />
                <div className='client-status-show'>Return Status Sync is in progress for: <span>{String(currentAssessObj?.full_name ?? '') ? currentAssessObj?.full_name + '-' + currentAssessObj?.pan : currentAssessObj?.pan}</span></div>
                <div className='client-status-show'>Time Remaining:<span>About {Math.max(Math.ceil((totalCount - currentWorking) * .1), 1)} {Math.max(Math.ceil((totalCount - currentWorking) * .1), 1) > 1 ? 'Minutes' : 'Minute'}  </span>   <div className='show-progress-count'>Count:<span>{currentWorking ?? 1}/{totalCount}</span></div></div>
            </div></Draggable>}

        </>
    )
}

export default AssesseeSyncAllClientListProgressBar