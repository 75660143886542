/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable max-len */
/* eslint-disable radix */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable require-jsdoc */
import React, { useState, useEffect } from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";


import NumericInput from '../../components/NumericInput';
import { clickToCall } from "../../api/profileAPI";
import callblueIcon from '../../images/call-blue.png';
import callIcon from '../../images/call.png';
import ReactDOM from "react-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle, faInfoCircle, faSync

} from "@fortawesome/free-solid-svg-icons";
import ShowAlert from "../../components/ShowAlert";
import ExItrLogo from "../../images/logo/exitrlogo.png";
import ExItrDiwaliLogo from "../../images/logo/exitrlogo_diwali.svg"
import logouticon from "../../images/loginicon/logouticon.png";
import itPortalLoginIcon from "../../images/Income-tax-depart-icon-set.svg";
import {
  BASE_PATH,
  initialHeaderData,
  CALL_ME_ENABLED,
  HEADER_CONNECTOR_DIGEST_KEY,
  TOUR_STEPS,
  clientstatusListForRevamped,
  LOGO,
} from "../../app/constants";
import { selectHeaderData, setHeader } from "./headerSlice";
import SubscribePopup from "../Plans/SubscribePopup";
import LoadingIndicator from "../../components/loadingIndicator/LoadingIndicator";
import PanInfoAnimation from "../client/PanInfoAnimation";
import queryString from 'query-string';
import EditIcon from '../../images/edite02.png';
import { convertYearToFormattedYear } from "../income/FinancialDateUtilities";
import { getAssessmentYears, getMaxAssessmentYearForWorking, redirectToLoginPage } from "../../utils/UtilityFunctions";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import "./Header.scss";
import AssesseeSyncListProgressBar from "../ListofClient/ClientProgressBar";
import { AY_DROPDOWN_PATHS, EXCLUDE_COMPARE_REGIME_PATHS, LOGIN_PATHS } from "../../App";
import RegimeToggle from "./RegimeToggle";
import $ from 'jquery'
import ConnectorAdapter from "../../components/kdkConnector/ConnectorAdapter";
import { loginItServerData, runItConnectorAction } from "../../api/dashboardApi";
import UpdateITPassword from "../dashboard/UpdateITPassword";
import { callApi } from "../../api/businessProfessionApi";
import store from "../../app/store";
import { setEfilingStatus } from "./efilingStatusSlice";
import { selectEfilingStatusData } from "./efilingStatusSlice";
import { setCurrentAssessee, selectCurrentAssessee } from "./currentAssesseeSlice";
import moment from "moment";
import CompareRegime from "../income/computation/CompareRegime";
import AssesseeSyncAllClientListProgressBar from "../returnRegister/AssesseeSyncAllClientListProgressBar";
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { clearAuthToken } from "../../utils/AuthToken";


// Modify Logo, as per Cobrand identified. If not, default to Express ITR Logo
let logo_touse = ExItrLogo;
const logo_uc = LOGO.toUpperCase();
if (logo_uc === "EXITRDIWALI") {
  logo_touse = ExItrDiwaliLogo;
}


const Header = ({ history }) => {
  const headerData = useSelector(selectHeaderData);
  const dispatch = useDispatch();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const cureentAssessee = useSelector(selectCurrentAssessee);

  // Determine Assessment Year to use - It handles broken link scenario when blank assessment_year is ther ein  query params

  // WARNING: Dont change following code - Madhur
  const ay = Number(
    (params.assessment_year !== undefined)
      ? (params.assessment_year ? params.assessment_year : getMaxAssessmentYearForWorking())
      : (headerData?.asyear ?? 0)
  );
  const asYears = getAssessmentYears()
  const selectedAy = {
    "label": `${ay}-${(ay + 1) % 100} `,
    "value": `${ay}`
  }
  // const tourPaths = [
  //   `${BASE_PATH}dashboard`,
  //   `${BASE_PATH}client/recent`,
  //   `${BASE_PATH}client/all`,
  // ];


  const planNotShowPath = [
    `${BASE_PATH}plans`,
    `${BASE_PATH}profile`,
    `${BASE_PATH}subscription`,
    `${BASE_PATH}user-management`,
    `${BASE_PATH}user-profile`,
    `${BASE_PATH}login`,
    `${BASE_PATH}signup`,
    `${BASE_PATH}forgetpass`
  ];
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPanInfoPopup, setShowPanInfoPopup] = useState(false);
  const [callMeMobileNumber, setCallMeMobileNumber] = useState('');
  const [loginItPortal, setLoginItPortal] = useState(false)
  const [loginItPortalFunc, setLoginItPortalFunc] = useState(false)
  const [port, setPort] = useState(0);
  const [updatePassPopup, setUpdatePassPopup] = useState(false)
  const eFilingStatusData = useSelector(selectEfilingStatusData);

  const disableBody = target => disableBodyScroll(target)
  const enableBody = target => enableBodyScroll(target)

  const [steps, setSteps] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: []
  });

  const onExit = () => {
    let new_steps = steps;
    new_steps.stepsEnabled = false;
    setSteps({ ...new_steps });
    localStorage.setItem(`intro_guide_it_${headerData.mobile_number}`, moment().unix());
  }

  const onstartIntro = () => {
    let new_steps = steps;
    new_steps.stepsEnabled = true;
    setSteps({ ...new_steps });
  }

  const checkIntroguideLater = (intro_guide_ts) => {
    if (intro_guide_ts === null) {
      return false;
    }
    return true;
  };



  useEffect(() => {
    setCallMeMobileNumber(headerData.mobile_number);
  }, [headerData.mobile_number])

  const executeClickToCall = async () => {

    if (callMeMobileNumber >= 5999999999 && callMeMobileNumber <= 9999999999) {
      setLoading(true);
      const data = {
        mobile_no: parseInt(callMeMobileNumber),
        call_type: 1,
      };
      try {
        const result = await clickToCall(data);
        setSuccess(result.message);
      } catch (err) {
        setError(err.message);
        // console.error('Error in Call Me API: ', err);
      }
      setLoading(false);
    }
    else {
      setError("Invalid Mobile Number");
    }
  }

  const logOut = (e) => {
    e.preventDefault();
    clearAuthToken();
    dispatch(setHeader(initialHeaderData));
    redirectToLoginPage();
  };

  const getStatusName = (statusId) => {
    const index = clientstatusListForRevamped.findIndex(item => item.value === statusId);
    if (index !== -1) {
      return (clientstatusListForRevamped[index]?.label)
    }

    return ''

  }
  const runLoginIt = async (assesseeId, port) => {
    if (port && assesseeId) {
      setLoading(true);
      const rowData = {}
      rowData.assessee_id = assesseeId;
      try {
        const serverApiResult = await loginItServerData(rowData);
        const EncryptLoginData = serverApiResult?.data
        const EncryptLoginDataHeader = serverApiResult?.headers ?? null;
        const connectorRequest = {
          "data": EncryptLoginData?.data
        };
        let extraHeader = {};
        extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = EncryptLoginDataHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;
        try {
          await runItConnectorAction(port, connectorRequest, extraHeader);
        } catch (err) {
          setError(err.message);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
        setLoginItPortalFunc(false)
      }
    }
  };

  const getFilingStatus = async (assessee_id, return_id) => {
    try {
      const result = await callApi('get', `/api/v1/returns/filing-status?assessee_id=${assessee_id}&return_id=${return_id}`);
      if (result?.data) {
        store.dispatch(setEfilingStatus({ ...result?.data }))
      }
    } catch (err) {
      console.error("error", err)
    }
  }


  useEffect(() => {
    if (port && loginItPortalFunc && params?.assessee_id) {
      runLoginIt(params?.assessee_id, port);
    }
    //eslint-disable-next-line
  }, [port, loginItPortalFunc, params?.assessee_id])

  useEffect(() => {
    if (params?.assessee_id && params?.return_id) {
      getFilingStatus(params?.assessee_id, params?.return_id);
    }
  }, [params?.assessee_id, params?.return_id])
  $(function () {
    $('.show-tooltip [data-toggle="tooltip"]').tooltip()
  })

  const setTourSteps = (pathName) => {  
    const clientMaster = [`${BASE_PATH}client/recent`, `${BASE_PATH}client/all`];
    let tourSteps = TOUR_STEPS['dashbaord'];
    if(clientMaster.includes(pathName)) {
      tourSteps = TOUR_STEPS['client_master'];
    }

    //update steps in tour object
    let new_steps = steps;
    new_steps.steps = tourSteps;
    setSteps({ ...new_steps });
  }

  useEffect(() => {    
    setTourSteps(location.pathname);
    if (headerData?.mobile_number && headerData?.organization_type && headerData?.organization_name) {
      const intro_guide_ts = localStorage.getItem(`intro_guide_it_${headerData?.mobile_number}`);
      if (!checkIntroguideLater(intro_guide_ts)) {
        onstartIntro();
      }
      $("#intro_icon").on("click", function () {
        $("#restart_guide").trigger("click");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerData?.mobile_number, headerData?.organization_type, headerData?.organization_name]);


  useEffect(() => {
    if (headerData?.mobile_number && !headerData.plan_valid) {
      setShowSubscribePopup(true);
      $(document).ready(function () {
        setTimeout(() => {
          $('.planexpiredalert').click(function () {
            setShowSubscribePopup(true);
            return false
          });
        }, 500);

      });
    }
  }, [headerData?.mobile_number, headerData?.plan_valid, location.pathname]);

  const callCompareRegimeConditionally = (AY) => {
    let statusArray = [1, 2, 18, 19, 20, 21];
    if (AY >= 2024) {
      statusArray.push(5, 6, 8, 22, 23, 24, 25, 27, 28, 29)
    }
    return statusArray
  }


  return (
    <>
      <header id="header">
        {headerData?.mobile_number && headerData?.plan_valid && location.pathname.indexOf('ay-wise') === -1 &&
          <>
            <AssesseeSyncListProgressBar />
            <AssesseeSyncAllClientListProgressBar display={false} />
          </>

        }



        {loading ? <LoadingIndicator /> : null}

        {updatePassPopup &&
          <UpdateITPassword
            assesseeId={params?.assessee_id}
            setUpdatePassPopup={setUpdatePassPopup}
            headerData={headerData}
            updatePassPopup={updatePassPopup}
          />
        }

        {loginItPortal
          ? <ConnectorAdapter
            show={loginItPortal}
            handleClose={() => setLoginItPortal(false)}
            setPort={setPort}
            handleProceed={() => {
              setLoginItPortalFunc(true)
            }}
          />
          : null
        }

        <ShowAlert
          autoHide={error ? false : true}
          success={success}
          error={error}
          onClose={() => {
            setError(null);
            setSuccess(null);
          }}
        />

        {showPanInfoPopup
          && (
            <PanInfoAnimation
              headerData={headerData}
              showPanInfoPopup={showPanInfoPopup}
              onHide={() => {
                setShowPanInfoPopup(false);
              }}
              loading={100}
              params={params}
            />
          )}

        {!planNotShowPath.includes(location.pathname) && <SubscribePopup
          show={showSubscribePopup}
          handleClose={(msg = "") => {
            setShowSubscribePopup(false);
            if (msg) {
              setSuccess(msg);
            }
          }}
          history={history}
        />}
        <nav className="navbar ">
          <div className="container-fluid">
            <div className="navbar-header col-xl-5 col-lg-6 col-md-4 col-sm-4  col-xs-12">
              <div className="headerlogodispinle">
                <Link className="navbar-brand mainexplogo" to={BASE_PATH}>
                {
                  logo_touse === ExItrDiwaliLogo
                    /* For object tag, need to set pointer-events: none for clickable link. Ref: https://stackoverflow.com/a/17133804/2469308 */
                    ? <object style={{ pointerEvents: "none" }} type="image/svg+xml" data={ExItrDiwaliLogo} aria-labelledby="Express ITR" width="120" height="45"></object>
                    : <img src={logo_touse} alt="Express ITR" />
                }
                </Link>

                <ul className="compboxtoppadd">
                  <li>
                    {(headerData.assessee_full_name && params?.assessee_id) &&

                      <>


                        <div className="exprit_mainexplogo" style={{ textAlign: 'left', color: 'rgb(49, 113, 155)', fontSize: '13px' }}>
                          <div className="client_name_header_fullname" style={{ position: 'relative', fontWeight: '700' }}>
                            <span data-toggle="tooltip" data-placement="top" title={headerData.assessee_full_name}>
                              {headerData.assessee_full_name}
                            </span>
                          </div>
                          <div className="exprit_infoiconpan_boxbottom">
                            <div className="exprit_infoiconpan">
                              <span data-toggle="tooltip" className="planexpiredalert" data-placement="top" title={headerData.assessee_pan} onClick={() => setShowPanInfoPopup(true)}>
                                {/* <span data-toggle="tooltip" data-placement="top" title={headerData.assessee_pan} onClick={() => null}> */}
                                {headerData.assessee_pan}
                                <span className="expit_infoiconheader" title="PAN Insights">
                                  <FontAwesomeIcon icon={faInfoCircle} />
                                </span>
                              </span>
                            </div>
                            {String(headerData?.display_status_code).length > 0 && <>
                              <span style={{ padding: '0px 7px 6px 11px', color: 'rgb(252, 125, 114)', verticalAlign: 'super' }} >|</span>
                              <span className="client_name_header" title={getStatusName(headerData?.display_status_code)}>{getStatusName(headerData?.display_status_code)} </span>
                            </>}

                            <span className="pl-0" style={{ verticalAlign: 'super' }}>

                              <span style={{ padding: '0px 7px 6px 11px', color: 'rgb(252, 125, 114)', verticalAlign: 'super' }}>|</span>
                              <span className="client_name_header" title={headerData?.assessee_res_status === 'NRI' ? 'Non-Resident' : headerData?.assessee_res_status === 'NOR' ? 'Not Ordinary Resident' : 'Resident'}  > {headerData?.assessee_res_status === 'NRI' ? 'Non-Resident' : headerData?.assessee_res_status === 'NOR' ? 'Not Ordinary Resident' : 'Resident'}  </span>

                            </span>
                            <span className="pl-0">
                              {
                                (location.pathname.indexOf('adddetail') === -1) && (
                                  <Link target="_blank" className="btn btn-default  mr-1 planexpiredalert" style={{ padding: '3px 0px 0px 10px', display: 'flex', border: '0px, ' }} to={`${BASE_PATH}client/adddetail?assessee_id=${params?.assessee_id}`}
                                    title="Edit Client"
                                  >
                                    <img src={EditIcon} width="14" alt="" />
                                  </Link>
                                )
                              }
                            </span>
                            <span style={{ padding: '0px 7px 6px 11px', color: 'rgb(252, 125, 114)', verticalAlign: 'super' }} >|</span>
                            <span className="pl-0 planexpiredalert" onClick={() => { if (headerData?.assessee_itdportal_isverified) { setLoginItPortal(true) } else { setUpdatePassPopup(true) } }} style={{ cursor: "pointer" }}>
                              <img className="it-portal-login" src={itPortalLoginIcon} width="28" alt="Login at IT Portal" title="Login at IT Portal" />
                            </span>
                          </div>
                        </div>

                      </>
                    }
                  </li>
                </ul>

              </div>
            </div>

            {!LOGIN_PATHS.includes(location.pathname) && (
              <div className="col-xl-7 col-lg-6 col-md-8 col-sm-8 col-xs-12 mb-0 pb-0 ">
                <div className="dispinlinerightnav">
                  <ul className="headernavdropmd mb-0 pb-0" style={{ minHeight: "47px" }}>
                    {params?.assessee_id && params?.return_id && ([1, 2].includes(headerData?.assessee_status) || ([18, 19, 20, 21].includes(headerData?.assessee_status)
                      && headerData?.assessee_res_status !== "NRI") || ([5, 6, 8, 22, 23, 24, 25, 27, 28, 29,17].includes(headerData?.assessee_status)
                        && headerData?.asyear >= 2024))
                      && params?.isaudit !== '1' && <RegimeToggle asYears={headerData?.asyear} setError={setError} />}


                    {AY_DROPDOWN_PATHS.includes(location.pathname) ?

                      <li className="exprit_headullipaddreight header-sync-class">
                        <div style={{ textAlign: 'right', color: '#fc7d72', fontSize: '12px', fontWeight: '600', marginTop: '5px', paddingRight: '0rem', display: 'flex', flexDirection: 'column' }}>
                          <div className="exprit_rightsbox_taxtoppadd" style={{ justifyContent: 'flex-end' }}>

                            <span style={{ paddingRight: '5px', color: 'rgb(204, 204, 204)' }}>A.Y.</span>
                            <span className="exprit_loaderboxlpad">
                              <DropDownList
                                data={asYears}
                                className="header_select headerselect"
                                popupSettings={{ className: 'headerselectoption' }}
                                textField="label"
                                dataItemKey="value"
                                value={selectedAy}
                                onChange={(e) => {
                                  history.push(`${BASE_PATH}dashboard?assessee_id=${params?.assessee_id}&assessment_year=${e.target.value.value}&wm=${params?.wm ?? '0'}`);
                                }}
                                id="yearDropDownPopup"
                              />
                            </span>

                          </div>
                          <span className="sync_status"><span className="boxalign">
                            {cureentAssessee.last_sync_status === 'IN_PROGRESS' ? <>{cureentAssessee.portFound ? 'Sync started on: ' : 'Sync Now '}</> : ''}
                            {cureentAssessee.last_sync_status === 'COMPLETE' ? 'Last synced on: ' : ''}
                            {cureentAssessee.last_sync_status === 'FAILURE' ? 'Last sync failed on: ' : ''}
                            {!cureentAssessee.last_sync_status || cureentAssessee.status === '' ? 'Sync Now ' : ''}
                            {cureentAssessee?.last_synced_at && cureentAssessee.portFound ? moment(cureentAssessee?.last_synced_at).format('DD/MM/YYYY, h:mm a') : ''}
                            <span data-toggle="tooltip" data-placement="top" title="Refresh Portal Data">
                              {(cureentAssessee.last_sync_status === 'IN_PROGRESS') ? <FontAwesomeIcon icon={faSync} className={`refrsh_sync ${cureentAssessee.portFound ? 'rotate_icon' : ''} `} /> :
                                <FontAwesomeIcon icon={faSync} className="refrsh_sync planexpiredalert" onClick={() => {
                                  if (headerData?.assessee_itdportal_isverified) {
                                    store.dispatch(setCurrentAssessee({ syncAgain: 1, last_sync_status: 'IN_PROGRESS', last_synced_at: new Date(), portFound: false }))
                                  } else {
                                    setUpdatePassPopup(true)
                                  }
                                }} />
                              }
                            </span></span></span>
                        </div>
                      </li>

                      :

                      <li className="exprit_headullipaddreight">
                        <div style={{ textAlign: 'right', color: 'rgb(252, 125, 114)', fontSize: '12px', fontWeight: '600', marginTop: '5px', paddingRight: '0rem' }}>
                          <div className="exprit_rightsbox_taxtoppadd">
                            {params?.assessee_id && params?.return_id &&
                              <span className="exprit_dashboard_iconbox">
                                {params?.isaudit === '1' ?
                                  <>
                                    <span className="pr-1">
                                      <Link className="btn btn-default  header-comptation" to={`${BASE_PATH}efiling-review-audit?assessee_id=${params?.assessee_id}&return_id=${params?.return_id}&isaudit=1`} >
                                        e-Filing
                                      </Link>
                                    </span>

                                    {/* <span className="show-tooltip">
                                      <div className="btn btn-default  header-comptation mr-1 disabled" data-toggle="tooltip" data-placement="top" data-original-title="Coming Soon"   >
                                        Computation
                                      </div>
                                    </span> */}
                                  </>
                                  : <>
                                    <span className="pr-1">
                                      <Link className="btn btn-default  header-comptation" to={`${BASE_PATH}efiling?assessee_id=${params?.assessee_id}&return_id=${params?.return_id}`}>
                                        e-Filing
                                      </Link>
                                    </span>

                                    <span className="">
                                      <Link className="btn btn-default  header-comptation mr-1" to={`${BASE_PATH}computation?assessee_id=${params?.assessee_id}&return_id=${params?.return_id}`} title="Computation"                                >
                                        Computation
                                      </Link>
                                    </span>
                                  </>}
                              </span>
                            }

                            {(params.assessment_year !== undefined || params?.return_id) &&

                              <>

                                {params?.assessee_id && params?.return_id &&
                                  <span style={{ padding: '0px 7px 6px 4px', color: 'rgb(252, 125, 114)' }}>|</span>}

                                <span style={{ paddingRight: '5px', color: 'rgb(204, 204, 204)' }}>A.Y.</span>

                                {
                                  !AY_DROPDOWN_PATHS.includes(location.pathname)
                                    ?
                                    <span className="header_drop_labelbox mr-1">
                                      <span className="header_drop_labelbox_textfs">{convertYearToFormattedYear(ay)}</span>
                                    </span>
                                    :
                                    <span className="exprit_loaderboxlpad">
                                      <DropDownList
                                        data={asYears}
                                        className="header_select headerselect"
                                        popupSettings={{ className: 'headerselectoption' }}
                                        textField="label"
                                        dataItemKey="value"
                                        value={selectedAy}
                                        onChange={(e) => {
                                          history.push(`${BASE_PATH}dashboard?assessee_id=${params?.assessee_id}&assessment_year=${e.target.value.value}&wm=${params?.wm ?? '0'}`);
                                        }}
                                        id="yearDropDownPopup"
                                      />
                                    </span>

                                }
                              </>}
                          </div>
                        </div>
                      </li>
                    }
                    <li className="dropdmenufr">
                      {!LOGIN_PATHS.includes(location.pathname) && (
                        <div className="userprodropd">
                          <ul className="">

                            <li
                              className="nav-item dropdown userprodropdlist"
                            >
                              <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                id="navbardrop"
                                data-toggle="dropdown"
                              >
                                <span className="profile_img_bg dark_color_text">
                                  <svg
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 16 16"
                                    className="bi bi-person-fill"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                                    />
                                  </svg>
                                </span>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <div
                                  className="dropdown-item profileusr"
                                  to={BASE_PATH}
                                >
                                  <span
                                    className="black_color_text usertextoverflow"
                                    style={{
                                      fontSize: "12px",
                                      padding: "0rem",
                                      marginTop: "0rem",
                                      borderRadius: "0rem",
                                    }}
                                  >
                                    <span
                                      className="pl-0"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title={headerData.full_name}
                                    >
                                      {headerData.full_name}
                                    </span>
                                  </span>
                                  <span className="activeusrp useractive_doticon">
                                    <FontAwesomeIcon icon={faCircle} />
                                  </span>
                                </div>
                                <div className="dropdown-divider" />

                                <Button
                                  className="dropdown-item"
                                  onClick={() => {
                                    history.push(`${BASE_PATH}profile`);
                                  }}
                                  style={{
                                    fontSize: "12px",
                                    padding: "0.2rem 0.5rem",
                                  }}
                                >
                                  Profile Settings
                                </Button>
                                <div className="dropdown-divider" />
                                <Button
                                  className="dropdown-item"
                                  onClick={() => {
                                    history.push(`${BASE_PATH}subscription`);
                                  }}
                                  style={{ fontSize: '12px', padding: '0.2rem 0.5rem' }}
                                >
                                  Subscription Plan
                                </Button>

                                {(headerData.user_role.toUpperCase() === 'OWNER') && (
                                  <>
                                    <div className="dropdown-divider" />
                                    <Button
                                      className="dropdown-item planexpiredalert"
                                      onClick={() => {
                                        history.push(`${BASE_PATH}user-management`);
                                      }}
                                      style={{ fontSize: '12px', padding: '0.2rem 0.5rem' }}
                                    >
                                      User Management
                                    </Button>
                                  </>
                                )}
                                {/* {tourPaths.includes(location.pathname) &&
                                  <> */}
                                <div className="dropdown-divider" />
                                <Button
                                  className="dropdown-item"
                                  id="intro_icon"
                                  style={{ fontSize: '12px', padding: '0.2rem 0.5rem' }}
                                >
                                  Guided Tour
                                </Button>
                                {/* </>
                                } */}
                                <div className="dropdown-divider" />

                                <Button
                                  className="dropdown-item logdisinline"
                                  onClick={(e) => logOut(e)}
                                  style={{ fontSize: "12px" }}
                                >
                                  Logout
                                  <span className=" logicntopm">
                                    <img
                                      className=""
                                      src={logouticon}
                                      alt="logout"
                                    />
                                  </span>
                                </Button>
                              </div>
                            </li>
                          </ul>


                          {/* Start call me */}
                          {CALL_ME_ENABLED &&
                            <ul className="callmerightbox" >
                              <li className="nav-item dropdown dropleft callmemainbox">
                                <span className="nav-link-btn callmebgclr" data-toggle="dropdown">
                                  <span className="callicon"><img src={callIcon} alt="call" /></span>
                                  <span className="calltext planexpiredalert" id="zps_call_me_valid_plan">Call Me</span>
                                </span>

                                <div className="dropdown-menu cllmeinnerdown">
                                  <div className="dropdown-item headerscetbborder">
                                    <span className="callicon"><img src={callblueIcon} alt="call" /></span>
                                    <span className="headertextclr"> Get a Call Back from Us </span>
                                    <span className="closeiconright">
                                      <i className="fa fa-times" aria-hidden="true"></i>
                                    </span>
                                  </div>
                                  <div className="textboxinnersect">
                                    <p className="contentarea">
                                      Enter the Mobile No. on which you want to get a call back from us:
                                    </p>
                                    <div className="inputboxarea">
                                      <NumericInput
                                        type="text"
                                        className="form-control inputcallme"
                                        placeholder="Enter Mobile Number"
                                        value={callMeMobileNumber}
                                        maxLength={10}
                                        onChange={(e) => setCallMeMobileNumber(e.target.value)}
                                        id="call_me_input"
                                      />
                                    </div>
                                    <div className="callmebtnbox">
                                      <button className="btn btn-default callmedonebtn" onClick={() => executeClickToCall()}> Done</button>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          }
                          {/* End call me */}

                        </div>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </nav>
        {eFilingStatusData?.is_filed === 'Y' && (params?.assessee_id && params?.return_id && params?.isaudit !== '1') ?
          <div className="efiling-warn">{`You have already filed IT return for A.Y. ${eFilingStatusData?.assessment_year} on ${eFilingStatusData?.filing_date}. However, if you update any data, you will lose your original return's data and computation.`}</div> : null}
        {(!EXCLUDE_COMPARE_REGIME_PATHS.includes(location.pathname) && params?.assessee_id && params?.return_id && callCompareRegimeConditionally(ay).includes(headerData?.assessee_status)) &&
          ReactDOM.createPortal(<CompareRegime returnId={params?.return_id} assesseeId={params?.assessee_id} />, document.querySelector('body'))
        }

        {headerData.mobile_number && !loading &&
          <Tour
            steps={steps.steps}
            isOpen={steps.stepsEnabled}
            onAfterOpen={disableBody}
            onBeforeClose={enableBody}
            onRequestClose={onExit}
            startAt={0}
            maskSpace={5}
            scrollDuration={50}
            showNumber={false}
            closeWithMask={false}
            nextButton="Next"
            prevButton="Prev"
            closeButton="End Tour"
            highlightedMaskClassName="highlight_selector"
          />}
        <span id="restart_guide" onClick={() => onstartIntro()}></span>


      </header>


    </>
  );
};

const HeaderWrapper = withRouter(Header);
export default HeaderWrapper;
