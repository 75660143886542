/* 
  *****************
  This slice is for app level data, for any data which we wants in store, this slice can be used.
  *****************
*/
/* eslint-disable array-callback-return */
import { createSlice } from '@reduxjs/toolkit';

const initialData = {
    cl11b: [],
    cl11c:[]
};

export const particular11Slice = createSlice({
  name: 'particular11',
  initialState: initialData,
  reducers: {
    setCl11b: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state["cl11b"] = action.payload;
    },
    setCl11c: (state, action) => {
        state["cl11c"] = action.payload;
    },
  },
});

export const { setCl11b, setCl11c } = particular11Slice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectParticular11Data = state => state.particular11;

export default particular11Slice.reducer;
