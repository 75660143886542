/* eslint-disable no-throw-literal */
import axios from 'axios';
import { BASE_URL } from '../app/constants';
import { handleSuccess, handleError, handleErrorWithCode } from './responseHandler';
import { getConfig } from './common';
import { handleConnectorError } from './responseHandler';
const connectorError = "Unable to connect to the Express Connector. Please ensure that the Expressor Connector is running. If the problem still persists, then Close the Browser completely and restart.";


export async function getReturnId(assesseeId, ay, setHeader = true, audit = '', newReturn = '', ackNo = '') {
  const url = `${BASE_URL}/api/v1/returns?assessee_id=${assesseeId}&assessment_year=${ay}&audit=${audit}&new_return=${newReturn}&ack_no=${ackNo}`;
  try {
    const clientData = await axios.get(url, getConfig());
    if (Number(clientData.data.status) !== 1) {
      throw (clientData.data.message);
    }
    if (setHeader) return handleSuccess(clientData.data);
    return clientData.data
  }
  catch (err) {
    throw handleError(err);
  }

}



export async function callSyncDashboard(port, data, headers) {
  if (!port) throw { code: 404, message: connectorError };
  const url = `http://127.0.0.1:${port}/api/v1/itr/itefl/login`;
  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      }
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}





export async function starySync(port, data, headers) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/itr/itefl/start-sync-it`;
  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      }
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}



export async function syncPipeLineStart(data) {
  const url = `${BASE_URL}/api/v1/itportal/start-sync`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }

    return result;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function markAssesseeUnVerified(data) {
  const url = `${BASE_URL}/api/v1/assesseemanager/itdportal-password-unverified`;

  try {
    const clientData = await axios.put(url, data, getConfig());
    if (Number(clientData.data.status) !== 1) {
      throw (clientData.data.message);
    }
    return handleSuccess(clientData.data);
  }
  catch (err) {

    throw handleError(err);
  }

}



export async function getPdfServerData(data) {
  const url = `${BASE_URL}/api/v1/itportal/download-pdf?assessee_id=${data.assessee_id}&assessment_year=${data.assessment_year}&pdf_type_ref=${data.pdf_type_ref}`;
  try {
    const result = await axios.get(url, getConfig());

    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result;
  } catch (err) {
    throw handleError(err);
  }
}


export async function runItConnectorAction(port, data, headers) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/itr/itportal/gui/action/perform`;
  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      }
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}



export async function loginItServerData(data) {
  const url = `${BASE_URL}/api/v1/itportal/login?assessee_id=${data.assessee_id}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result;
  } catch (err) {
    throw handleError(err);
  }
}