/* eslint-disable import/prefer-default-export */
import { getAuthToken } from '../utils/AuthToken';

export const loginAxiosConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export const getConfig = () => ({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAuthToken(true)}`,
  }
});


export const getConfigForDeleteHttpVerb = (data) => ({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAuthToken(true)}`,
  }, data
});
export const getConnectorConfig = { crossdomain: true };